<template>
  <div class="information">
    <titleNav title="社保小助手" :left-arrow="true" left-text="" />
    <img src="@/assets/images/c.png" alt="">
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
export default {
  components: {
    titleNav,
  },
};
</script>

<style scoped lang="scss">
.information {
    img{
        width: 100%;
    }
}
</style>
